import React from "react";
import "../App.css";
import {Box, Container, createMuiTheme, Grid, MuiThemeProvider, responsiveFontSizes, Typography} from "@material-ui/core";
import mainPageImage from "../images/FactoryFloorWithTwoWorkers.webp"


const textData = {
    "title": "Welcome to ",
    "subtitle": "Your Premium Provider",
    "bodyContent": "As a leader in the manufacturing industry, Papier International has set the bar with its unwavering commitment to quality and exceptional client service. Through our extensive inventory of diverse and customizable products, our talented team goes above and beyond to make sure your needs are not only met, but exceeded."
}

export default function HomeComponent() {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <Container style={{color:"red"}}>
                <Grid container spacing={3} justify={"center"}>
                    <Grid item xs={12} lg={9}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            pt={10}
                            pb={4}
                        >
                            <img width={"100%"} src={mainPageImage} alt="Papier Factory" />
                            {/*<img width={"100px"} src={"./images/PapierRolex_edited.webp"} alt={"Papier International"}/>*/}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={9} >
                        <Typography variant={"h3"} align={"center"}>{textData.title} {process.env.REACT_APP_COMPANY_NAME}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Typography variant={"h5"} align={"center"}>{textData.subtitle}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Typography variant={"body1"} align={"center"}>{textData.bodyContent}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </MuiThemeProvider>
    );
}
