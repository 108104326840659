import React from "react";
import "../App.css";
import {
    Box, Card, CardContent, CardMedia,
    Container,
    createMuiTheme,
    Grid,
    MuiThemeProvider,
    responsiveFontSizes,
    Typography
} from "@material-ui/core";
import siliconBasePaperImage from "../images/IMG_20190220_113721.webp"
import selfAdhesivePaperImage from "../images/IMG_20190220_113654_1.webp"


const textData = {
    "heading": "Our Inventory",
    "subtitle": "We produce standard products with superior quality.",
    "inventory": [
        {
            "title": "Silicon Base Paper",
            "detail": "Silicon Base Paper is the requirement of all the Gumming Paper Manufacturing Units. We provide you the best quality and can even provide customized variety. If you haven't tried our material ever, then you must need to give it a go.",
            "image": siliconBasePaperImage
        },
        {
            "title": "Self-Adhesive Paper",
            "detail": "We provide one of the best quality of Self-Adhesive Paper in the market at the best rate. There is much various quality of product available with us in Self-Adhesive Paper, like Cromo, Maplitho, Direct Thermal, PiggyBack, Sandwich and Pearl PP.  Self- Adhesive Paper is also known as Pressure sensitive Paper.",
            "image": selfAdhesivePaperImage
        }
    ]
}

const section = {
    height: "100%",
};

export default function InventoryComponent() {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <Box mt={4} pt={10} pb={4} bgcolor="error.main">
                <Container>
                    <Grid container justify={"center"}>
                        <Grid item xs={12} lg={9}>
                            <Typography variant={"h3"} align={"center"}
                                        color={"textPrimary"}>{textData.heading}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Typography variant={"h5"} align={"center"}
                                        color={"textPrimary"}>{textData.subtitle}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Box my={5} px={3}>
                                <Grid container spacing={3} justify={"center"}>
                                    {textData.inventory.map((tile, index)=>{
                                        return(
                                            <Grid item xs={12} md={6} key={index}>
                                                <Card style={section}>
                                                    <CardMedia
                                                        component={"img"}
                                                        image= {tile.image}
                                                        title={tile.title}
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {tile.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {tile.detail}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </MuiThemeProvider>
    );
}
