import React from "react";
import "../App.css";
import {
    Box,
    Container,
    createMuiTheme,
    Grid, Link,
    MuiThemeProvider,
    responsiveFontSizes,
    Typography
} from "@material-ui/core";
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';



const textData = {
    "title": "WE WANT TO HEAR FROM YOU",
    "subtitle1": "Interested in placing an order or simply want to learn more about us?",
    "subtitle2":"Fill out the contact form and one of our team members will be happy to get in touch.",
    "owner": "Haresh Patel",
    "locations": [
        {
            "unitName": "Headquarter",
            "addressLine1": "Plot No. 3625, Road No. 3,",
            "addressLine2": "Sachin G.I.D.C., Surat",
            "email1": "papier_int@yahoo.com",
            "email2": "suratsales@papierinterntional.com",
            "phone": "(+91) 92653-44432",
        },{
            "unitName": "Manufacturing Plant 2",
            "addressLine1": "Plot No B-24, Sector 57,",
            "addressLine2": "Noida, UP, IN - 201301",
            "email1": "papierintdelhi@gmail.com",
            "email2": "delhisales@papierinterntional.com",
            "phone": "(+91) 93551-58245",
        }
    ]
}

const location = {
    center: {
        lat: 21.089891263594026,
        lng: 72.85970602657491
    },
    zoom: 14,
    text: process.env.REACT_APP_COMPANY_NAME,
};

const LocationPin = ({ text }) => (
    <div className="pin">
        <LocationOnIcon/>
        <p className="pin-text">{text}</p>
    </div>
)

export default function ContactComponent() {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <Box py={16} bgcolor="error.main">
                <Container>
                    <Grid container spacing={3} justify={"center"}>
                        <Grid item container alignContent={"center"} xs={12} lg={6}>
                            <Grid item xs={12}>
                                <Box mb={5}>
                                    <Typography variant={"h3"} align={"center"} color={"textPrimary"}>{textData.title}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={5}>
                                    <Typography variant={"h5"} align={"center"} color={"textPrimary"}>{textData.subtitle1}</Typography>
                                </Box>
                            </Grid>
                            <Grid container item xs={12} spacing={3} justify={"center"}>
                                {textData.locations.map((item, index)=>{
                                    return(
                                        <Box my={3} px={3} key={index}>
                                            <Grid item>
                                                <Typography color={"textPrimary"} variant={"h5"}>{item.unitName}</Typography>
                                                <Typography color={"textPrimary"} variant={"body1"}>{item.addressLine1}</Typography>
                                                <Typography color={"textPrimary"} variant={"body1"}>{item.addressLine2}</Typography>
                                                <Typography component={"a"} href={`mailto:${item.email1}`} color={"textPrimary"} variant={"body1"}>{item.email1}<br/></Typography>
                                                <Typography component={"a"} href={`mailto:${item.email2}`} color={"textPrimary"} variant={"body1"}>{item.email2}<br/></Typography>
                                                <Typography component={"a"} href={`tel:${item.phone}`} color={"textPrimary"} variant={"body1"}>{item.phone}<br/></Typography>
                                            </Grid>
                                        </Box>
                                    );
                                })}
                            </Grid>
                            {/*<Grid container justify={"center"} style={{marginTop: "4%", marginBottom: "4%"}} spacing={4} item xs={12}>*/}
                            {/*        <Grid item xs={1}>*/}
                            {/*            <Link href={"https://www.facebook.com/papierinternational"} target={"_blank"}  rel="noreferrer">*/}
                            {/*                <FacebookIcon color={"primary"} style={{background:"white"}} fontSize={"large"}/>*/}
                            {/*            </Link>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={1}>*/}
                            {/*            <Link href={"https://in.linkedin.com/company/papierinternational"} target={"_blank"}  rel="noreferrer">*/}
                            {/*                <LinkedInIcon style={{color: '#2867B2'}} fontSize={"large"}/>*/}
                            {/*            </Link>*/}
                            {/*        </Grid>*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Grid item xs={12} lg={6} >
                            <div style={{ height: '100%', width: '100%', minHeight: '300px'  }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
                                    defaultCenter={location.center}
                                    defaultZoom={location.zoom}
                                >
                                    <LocationPin
                                        lat={location.center.lat}
                                        lng={location.center.lng}
                                        text={location.text}
                                    />
                                </GoogleMapReact>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </MuiThemeProvider>
    );
}
