import React from "react";
import "../App.css";
import {
    Box, Card, CardContent, CardMedia,
    Container,
    createMuiTheme,
    Grid,
    MuiThemeProvider,
    responsiveFontSizes,
    Typography
} from "@material-ui/core";
import cuttingEdgeTechImage from "../images/IMG_20190220_113312_1.webp"
import materialImage from "../images/IMG_20190220_113721.webp"
import machineImage from "../images/IMG_20190220_113502.webp"
import packingImage from "../images/packing_1.webp"


const textData = {
    "heading": "Our Production Process",
    "subtitle": "We follow globally followed practices.",
    "inventory": [
        {
            "title": "CUTTING-EDGE TECHNOLOGIES",
            "detail": "Only the best",
            "image": cuttingEdgeTechImage
        },
        {
            "title": "FIRST-RATE MATERIALS",
            "detail": "We always use Fresh Paper.",
            "image": materialImage
        },
        {
            "title": "SUPERIOR QUALITY MACHINES",
            "detail": "Truly Top-Notch",
            "image": machineImage
        },
        {
            "title": "QUALITY PACKAGING",
            "detail": "Damage-free Product Packing",
            "image": packingImage
        }
    ]
}

const section = {
    height: "100%",
};

export default function ProductionComponent() {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);

    return (
        <MuiThemeProvider theme={theme}>
            <Box mt={14} style={{color:"red"}}>
                <Container>
                    <Grid container justify={"center"}>
                        <Grid item xs={12} lg={9}>
                            <Typography variant={"h3"} align={"center"}>{textData.heading}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Typography variant={"h5"} align={"center"}>{textData.subtitle}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Box my={5} px={3}>
                                <Grid container spacing={3} justify={"center"}>
                                    {textData.inventory.map((tile, index)=>{
                                        return(
                                            <Grid item xs={12} md={6} key={index}>
                                                <Card style={section}>
                                                    <CardMedia
                                                        component={"img"}
                                                        image= {tile.image}
                                                        title={tile.title}
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {tile.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {tile.detail}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </MuiThemeProvider>
    );
}
