import './App.css';
import HomeComponent from "./Components/Home";
import FooterComponent from "./Components/Footer/Footer";
import InventoryComponent from "./Components/InventoryComponent";
import ProductionComponent from "./Components/ProductionComponent";
import ContactComponent from "./Components/ContactComponent";
import Header from "./Components/NavBar/Header";
import {Element} from "react-scroll";

function App() {
  return (
      <div>
          <Header/>
          <Element name="about-us">
              <HomeComponent />
          </Element>
          <Element name="product">
            <InventoryComponent />
          </Element>
          <Element name="production">
              <ProductionComponent />
          </Element>
          <Element name="contact">
              <ContactComponent />
          </Element>
          <FooterComponent/>
      </div>
  );
}

export default App;
