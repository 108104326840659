import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export default function GeneralDialog(props) {
    // const {onClose, open, title, description, item} = props;
    const {onClose, open, item} = props;

    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{item.title}</DialogTitle>
            <DialogContent id="simple-dialog-content">
                <DialogContentText align={"justify"} color={"textPrimary"} id="simple-dialog-content-text">
                    {item.description}
                </DialogContentText>
                <DialogActions >
                    <Button style={{cursor:"pointer"}} onClick={handleClose} variant={"outlined"}>
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

GeneralDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // title: PropTypes.string.isRequired,
    // description: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired
};
