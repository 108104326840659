import {
    AppBar,
    Container,
    Hidden,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar
} from "@material-ui/core";
import * as React from "react";
import HideOnScroll from "./HideOnScroll";
import SideDrawer from "./SideDrawer";
import { Link } from "react-scroll";
import papierLogo from "../../images/PapierRolex_edited.webp"


const useStyles = makeStyles({
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        verticalAlign: "middle",
        // alignContent:"center",
        alignItems: "center"
    },
    navListDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `red`,
    }
});

const navLinks = [
    { title: `about us`, path: `about-us`, offset: -50 },
    { title: `product`, path: `product`, offset: 0 },
    { title: `production`, path: `production`, offset: -100 },
    { title: `contact`, path: `contact`, offset: 5 },
];

const Header = () => {
    const classes = useStyles();

    return (
        <>
            <HideOnScroll>
                <AppBar position="fixed" style={{"background": "white"}}>
                    <Toolbar component="nav" color="error.main">
                        <Container maxWidth="md" className={classes.navbarDisplayFlex}>
                                <Link
                                    to={"about-us"}
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset = {-50}
                                    className={classes.linkText}
                                    style={{"cursor":"pointer"}}
                                >
                                    <img width={"100px"} src={papierLogo} alt={"Papier International"}/>
                                </Link>

                            <Hidden smDown>
                                <List
                                    component="nav"
                                    aria-labelledby="main navigation"
                                    className={classes.navListDisplayFlex}
                                >
                                    {navLinks.map(({ title, path , offset}) => (
                                        <Link
                                            to={path}
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            offset = {offset}
                                            key={title}
                                            className={classes.linkText}
                                        >
                                            <ListItem button >
                                                <ListItemText primary={title} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer navLinks={navLinks} />
                            </Hidden>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="back-to-top-anchor" />
        </>
    );
};

export default Header;
