import React from "react";

const PrivacyPolicy = {
    title: 'Privacy Policy',
    description: [
        "We respect the privacy of our visitors. We collect only such personal information as is needed to provide the information, service, or assistance that you request.",
        <br/>, <br/>,
        <strong>Information We Collect Automatically</strong>,
        <br/>,
        "If you visit our website to browse, read, or download information:",
        <br/>, <ul>
            <li>Your web browser automatically sends us (and we may retain) information such as the:</li>
            <ul>
                <li>
                    Internet domain through which you access the Internet (e.g., yourServiceProvider.com if you use a commercial Internet service provider, or yourSchool.edu if you use an Internet account from your school);
                </li>
                <li>
                    Internet Protocol address of the computer you are using;
                </li>
                <li>
                    type of browser software and operating system you are using;
                </li>
                <li>
                    date and time you access our site; and
                </li>
                <li>
                    the Internet address of the site from which you linked directly to our site.
                </li>
            </ul>
            <li>
                We will use this information as aggregate data to help us maintain this site, e.g., to determine the number of visitors to different sections of our site, to ensure the site is working properly, and to help us make our site more accessible and useful.
            </li>
            <li>
                We will not use this information to identify individuals, except for site security or law enforcement purposes.
            </li>
            <li>
                We will not obtain personally-identifying information about you when you visit our site, unless you choose to provide such information.
            </li>
        </ul>,
        <br/>, <br/>,
        <strong>How Long We Keep Information</strong>,
        <br/>,
        "We may keep information that will collect for an unlimited period of time.",
        <br/>, <br/>,
        <strong>Use of Cookies</strong>,
        <br/>, <br/>,
        "A cookie is a text-only string of information that a website transfers to the cookie file of the browser on your computer's hard disk so that the website can remember who you are. A cookie will typically contain the name of the domain from which the cookie has come, the \"lifetime\" of the cookie, and a value, usually a randomly generated unique number.",
        <br/>, <br/>,
        "When you visit our website we send you a cookie. Cookies may be used in the following ways: To help us recognise you as a unique visitor (just a number) when you return to our website and to allow us to tailor content or advertisements to match your preferred interests or to avoid showing you the same adverts repeatedly.",
        <br/>, <br/>,
        <strong>Google Analytics</strong>,
        <br/>, <br/>,
        "We use a tool called “Google Analytics” to collect information about use of this site. Google Analytics collects information such as how often users visit this site, what pages they visit when they do so, and what other sites they used prior to coming to this site. We use the information we get from Google Analytics only to improve this site. Google Analytics collects only the IP address assigned to you on the date you visit this site, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to this site is restricted by the ",
        <a href={"http://www.google.com/analytics/tos.html"} target={"_blank"} rel={"noreferrer"}>Google Analytics Terms of Use</a>,
        "(as amended for government websites) and the ",
        <a href={"http://www.google.com/privacypolicy.html"} target={"_blank"} rel={"noreferrer"}>Google Privacy Policy.</a>,
        " You can prevent ",
        <a href={"http://www.usa.gov/optout_instructions.shtml"} target={"_blank"} rel={"noreferrer"}>Google Analytics </a>,
        "from recognizing you on return visits to this site by disabling cookies on your browser.",
        <br/>, <br/>
    ]
};


export default PrivacyPolicy;
