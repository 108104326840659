import React from "react";
import {
    Box,
    Container, Grid, Link,
    Typography
} from "@material-ui/core";
import GeneralDialog from "./GeneralDialog";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function SocialBar() {
    return(
        <div style={{display: "table", margin: "auto", alignContent:"space-around"}}>
            <Link href={"https://www.facebook.com/papierinternational"} target={"_blank"}  rel="noreferrer">
                <FacebookIcon color={"primary"} style={{background:"white"}} fontSize={"large"}/>
            </Link>
            <Link href={"https://in.linkedin.com/company/papierinternational"} target={"_blank"}  rel="noreferrer">
                <LinkedInIcon style={{color: '#2867B2'}} fontSize={"large"}/>
            </Link>
        </div>
    );
}

function PolicyBar(){
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});

    const handleClickOpen = (item) => {
        setData(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setData({});
    };

    return (
        <Grid container spacing={4} justify="space-evenly">
            {footers.map((items) => (
                <Grid item key={items}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                        <Link style={{cursor: "pointer"}} onClick={() => handleClickOpen(items)}
                              variant="subtitle1" >
                            {items.title}
                        </Link>
                    </Typography>
                    <GeneralDialog open={open} item={data} onClose={handleClose}/>
                </Grid>
            ))}
        </Grid>
    );
}

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © Papier International '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const footerGradient = {
    background: "white",
    color: "black"
}

const footers = [TermsOfUse, PrivacyPolicy];

export default function FooterComponent() {
    return (
        <Box style={footerGradient}>
            <Container component="footer">
                <Box py={3} my={3}>
                    < SocialBar />
                </Box>
                <Box pb={3}>
                    <PolicyBar/>
                </Box>
                <Box pb={3}>
                    <Copyright/>
                </Box>
            </Container>
        </Box>
    );
}
