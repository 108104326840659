import React from "react";

const TermsOfUse = {
    title: 'Terms of use',
    description: [`We, ${process.env.REACT_APP_COMPANY_NAME}, a Company incorporated under the Companies Act, 1956 having registered office at Plot No. 3625, Road No. 36, Sachin GIDC, Surat, Gujarat, India - 394210, provide a series of information through our website.`,
    <br/>, <br/>,
    "This official site of http://papierinternational.com has been developed to provide general public information. The documents and information displayed in this site are for reference purposes only.",
    <br/>, <br/>,
    `The content on the site is updated on a continual basis. While ${process.env.REACT_APP_COMPANY_NAME} attempts to keep its web information accurate and timely, it neither guarantees nor endorses the content, accuracy, correctness or completeness of the information, text, graphics, hyperlinks, and other items contained on this server or any other server.`,
    <br/>, <br/>,
    `As a result of updates and corrections, web materials are subject to change without notice from ${process.env.REACT_APP_COMPANY_NAME}. Commercial use of web materials is prohibited without the written permission of the company.`,
    <br/>, <br/>,
    "Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to use or in connection with the making of any decision. No Information at this web site shall constitute an invitation to invest in the Company or any of its affiliates. Any use of this website or the Information is at your own risk. Neither the Company and its affiliates, nor their officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, or reliance upon, this website or the Information, or any website linked to this website.",
    <br/>, <br/>,
    `It would be your responsibility to evaluate any inaccuracy, error, or delay in, or omission of any such data, information, or message within our website or the transmission or delivery of any data, information, or message; or any loss, expense, cost or damage arising from or occasioned by any such inaccuracy, error, delay, or omission, non-performance, or interruption of any data, information, or message, or \"force majeure\" (i.e., flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications power failure, or equipment or software malfunction) or any other cause or circumstances beyond the reasonable control of ${process.env.REACT_APP_COMPANY_NAME}.`,
    <br/>, <br/>,
    `${process.env.REACT_APP_COMPANY_NAME} is neither responsible nor liable for any viruses or other contamination of your system, nor for any delays, inaccuracies, errors or omissions arising out of your use of the site or with respect to the material contained on the site. The company is not responsible for any special, direct, indirect, incidental, punitive or consequential damages that may arise from the use of, or the inability to use, the site and/or the materials contained on the site whether the materials contained on the site are provided by Papier International.`,
    <br/>, <br/>,
    `Some of the hyperlinks contained in this site may lead to resources outside the site. Information contained in any site linked from this site has not been reviewed for accuracy or legal sufficiency. ${process.env.REACT_APP_COMPANY_NAME} is not responsible for the content of any such external hyperlinks and so we encourage you to read the privacy statements of these linked sites as their privacy policy may differ from ours. References to any external links should not be construed as an endorsement of the links or their content.`,
    <br/>, <br/>,
        `No document from the http://papierinternational.com site should be copied, reproduced, published, downloaded, posted, transmitted or distributed in any manner, except for the case of a simple recording of documents on your personal computer for user’s personal use and without any commercial purpose. Users agree to indemnify ${process.env.REACT_APP_COMPANY_NAME}, its Directors, Employees, Subsidiaries and Affiliates harmless for any claim or contractual responsibility, criminal responsibility or any other claims, for all indirect or direct loss, incidents or ancillary of whatever nature resulting out of the use of this site or any information obtained from its site.`,
    <br/>, <br/>,
        "All disputes relating to the site or to the present notice shall be brought before the Court at Surat, Gujarat, India and will be governed according to Indian Law, independently of the rules concerning conflicting legislation. Use of the site http://papierinternational.com indicates your express agreement with the application of the present jurisdiction clause. In the event of one of the elements of these conditions of use being judged illegal, null or inapplicable for whatever reason, it will no longer be considered part of the conditions of use and will not affect the validity or application of the other elements.",
    <br/>, <br/>,
        <strong>Copyright / Trademarks</strong>,
    <br/>, <br/>,
        "All disputes relating to the site or to the present notice shall be brought before the Court at Surat, Gujarat, India and will be governed according to Indian Law, independently of the rules concerning conflicting legislation. Use of the site http://papierinternational.com indicates your express agreement with the application of the present jurisdiction clause. In the event of one of the elements of these conditions of use being judged illegal, null or inapplicable for whatever reason, it will no longer be considered part of the conditions of use and will not affect the validity or application of the other elements.",
    <br/>, <br/>,
        `Product design, graphics are the properties of ${process.env.REACT_APP_COMPANY_NAME} and fully protected. The information and content made available are governed by and to be interpreted in accordance with laws of India, without regard to the choice or conflicts of law provisions of any jurisdiction. If you use this web site from outside India, you are entirely responsible for compliance with all applicable local laws.`,
    <br/>, <br/>,
        `All the trademarks, logos and service marks, information and content provided on our website including its design structure and compilation are privately owned intellectual property rights of ${process.env.REACT_APP_COMPANY_NAME}. Unless otherwise noted by us, you shall not copy, download or and print documents from our website for any commercial purposes and shall not post, distribute transmit display publish sell or modify them or remove any copyright trade mark or other proprietary notices. For permission to use third party materials appearing on our website, please contact the copyright owner.`,
    <br/>,<br/>
    ]
};


export default TermsOfUse;
